import React from "react"
import { slugify } from "../../src/utils/slugify"
import Layout from "../components/layouts/Layout/layout"
import Magazine from "../components/sections/Magazine/Magazine"
import Seo from "../components/seo"
import componentFactory from "../utils/componentFactory"
import "./article-template-markdown.scss"
import "./article-template.scss"
import MagazineArticles from "../components/sections/Magazine/MagazineArticles/MagazineArticles"

const CategoryTemplate = ({ pageContext }) => {
  const item = pageContext.item
  const page = pageContext.page.attributes

  const components = page.contenu.map(i => componentFactory(i, pageContext))

  const magazineItems = pageContext.item.articles.map(i => i.attributes)
  const actionItems = pageContext.item.actions.map(i => {
    const { id, attributes } = i

    if (id === undefined || id === null) {
      console.warn(`Action with undefined or null id: ${JSON.stringify(i)}`)
    }

    return {
      ...attributes,
      id,
    }
  })
  const cat = pageContext.categories.find(c => c.id === item.id)

  const chemin = [...page.chemin]

  if (item.id !== 0) {
    if (cat.attributes.groupe)
      chemin.push({
        titre: cat.attributes.groupe,
        lien: page.url + slugify(cat.attributes.groupe),
      })
    chemin.push({ titre: cat.attributes.nom })
  }
  return (
    <>
      <Seo
        description={page.metaDescription}
        title={page.titre}
        lang="fr"
      ></Seo>
      <Layout chemin={chemin}>
        <Magazine
          title={page.titre}
          categories={pageContext.categories}
          selectedCategoryId={item.id}
          magazineItems={magazineItems}
          actionItems={actionItems}
          baseUrl={page.url}
        />
        <div style={{marginTop: "100px"}}></div>
        <MagazineArticles
          categories={pageContext.categories}
          groupe={item.group}
          magazineItems={magazineItems}
        />
        {components}
      </Layout>
    </>
  )
}

export default CategoryTemplate
